document.addEventListener('DOMContentLoaded', function () {
  const carouselInner = document.getElementById('carousel-inner');
  const carouselIndicators = document.getElementById('carousel-indicators');

  fetch('https://www.smtornillosytuercas.com/blog/wp-json/wp/v2/posts?_embed&per_page=3')
    .then(response => response.json())
    .then(posts => {
      posts.forEach((post, index) => {
        const carouselItem = document.createElement('div');
        carouselItem.classList.add('carousel-item');
        if (index === 0) carouselItem.classList.add('active');

        const card = document.createElement('div');
        card.classList.add('card', 'mx-auto', 'card-movil');
        card.style.width = '95vw';

        const link = document.createElement('a');
        link.href = post.link;
        link.target = '_blank';

        const img = document.createElement('img');
        img.classList.add('card-img-top');
        img.alt = 'Blog post image';
        img.src = getHighResolutionImage(post);

        link.appendChild(img);

        const cardBody = document.createElement('div');
        cardBody.classList.add('card-body');

        const dateH5 = document.createElement('h5');
        dateH5.classList.add('card-title');
        dateH5.textContent = formatDate(post.date_gmt);
        

        const textP = document.createElement('p');
        textP.classList.add('card-text');
        textP.innerHTML = post.title.rendered;

        const btnGroup = document.createElement('div');
        btnGroup.classList.add('btn-group-movil');

        const linkButton = document.createElement('a');
        linkButton.href = post.link;
        linkButton.target = '_blank'; 
        linkButton.classList.add('button-link2');
        linkButton.innerHTML = 'Ver <i class="fas fa-arrow-right"></i>';

        btnGroup.appendChild(linkButton);
        cardBody.appendChild(dateH5);
        cardBody.appendChild(textP);
        cardBody.appendChild(btnGroup);
        card.appendChild(link);
        card.appendChild(cardBody);
        carouselItem.appendChild(card);
        carouselInner.appendChild(carouselItem);

        const indicator = document.createElement('button');
        indicator.type = 'button';
        indicator.dataset.bsTarget = '#carouselBlog';
        indicator.dataset.bsSlideTo = index;
        indicator.ariaLabel = `Slide ${index + 1}`;
        if (index === 0) {
          indicator.classList.add('active');
          indicator.ariaCurrent = 'true';
        }
        carouselIndicators.appendChild(indicator);
      });
    })
    .catch(error => console.error('Error fetching posts:', error));

  function formatDate(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  function getHighResolutionImage(post) {
    if (post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0]) {
      const media = post._embedded['wp:featuredmedia'][0];
      return media.media_details.sizes.full ? media.media_details.sizes.full.source_url :
             media.media_details.sizes.large ? media.media_details.sizes.large.source_url :
             media.media_details.sizes.medium_large ? media.media_details.sizes.medium_large.source_url :
             media.media_details.sizes.medium ? media.media_details.sizes.medium.source_url :
             media.source_url;
    }
    return 'default-image.jpg'; // Ruta a una imagen por defecto
  }
});
