document.body.addEventListener('scroll', function() {
  const navbar = document.querySelector('.nav-symsa');
  const navbarHeader = document.querySelector('.navbar');
  const navLinks = document.querySelectorAll('.navbar-brand');
  const btnNav = document.querySelector('.btn-nav');
  const logoSymsa = document.getElementById('logo-symsa');
  const iconColor = document.querySelectorAll('.color-icons-nav');
  const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  const navHeight = navbar.offsetHeight;
  const bannerCarousel = document.getElementById('seccion-symsa-sujetadores');
  const bannerCarouselPosition = bannerCarousel.getBoundingClientRect().bottom + window.scrollY;

  navLinks.forEach(function(link) { 
    if (scrollTop > navHeight) {
      link.classList.remove('navbar-text-white');
      link.classList.add('navbar-text-black');
    } else {
      link.classList.remove('navbar-text-black');
      link.classList.add('navbar-text-white');
    }
  });

  iconColor.forEach(function(icon) { 
      if (scrollTop > navHeight) {
          icon.classList.remove('color-icon');
          icon.classList.add('color-icon-scroll');
      } else {
          icon.classList.remove('color-icon-scroll');
          icon.classList.add('color-icon');
      }
  });

if (scrollTop > navHeight) {
    
    logoSymsa.src = "img/logo-symsa-negro.svg";
    btnNav.classList.remove("button-link-nav");
    btnNav.classList.add("button-link-nav-scroll");
    navbar.classList.add('white');
  } else {
    logoSymsa.src = "img/logo-symsa-blanco.svg";
    btnNav.classList.remove("button-link-nav-scroll");
    btnNav.classList.add("button-link-nav");
    navbar.classList.remove('white');
    navbar.style.transition = 'background-color 0.5s ease';
  }

  if (scrollTop > bannerCarouselPosition) {
    navbarHeader.classList.remove('positionNavOriginal');
    navbarHeader.classList.add('positionNavScroll');
    navbarHeader.style.transition = 'top 0.5s ease';
  } else {
    navbarHeader.classList.remove('positionNavScroll');
    navbarHeader.classList.add('positionNavOriginal');
    navbarHeader.style.transition = 'top 0.5s ease';
  }
});


document.body.addEventListener('scroll', function() {
  const navbar = document.querySelector('.nav-symsa-m');
  const navbarHeader = document.querySelector('.navbar-m');
  const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  const navHeight = navbar.offsetHeight;
  const bannerCarousel = document.getElementById('seccion-symsa-sujetadores');
  const bannerCarouselPosition = bannerCarousel.getBoundingClientRect().bottom + window.scrollY;
  const logoSymsaMovil = document.getElementById('logo-symsa-movil');
  const navmov = document.querySelector('.navmov');


  if (scrollTop > navHeight) {
    navmov.classList.remove("bannertoggler");
    navmov.classList.add("bannertoggler-scroll");
    logoSymsaMovil.src = "img/logo-symsa-negro.svg";
    navbar.classList.add('white-m');
  } else {
    navmov.classList.add("bannertoggler");
    navmov.classList.remove("bannertoggler-scroll");
    logoSymsaMovil.src = "img/logo-symsa-blanco.svg";
    navbar.classList.remove('white-m');
    navbar.style.transition = 'background-color 0.5s ease';
  }

  if (scrollTop > bannerCarouselPosition) {
    navbarHeader.classList.remove('positionNavOriginal');
    navbarHeader.classList.add('positionNavScroll');
    navbarHeader.style.transition = 'top 0.5s ease';
  } else {
    navbarHeader.classList.remove('positionNavScroll');
    navbarHeader.classList.add('positionNavOriginal');
    navbarHeader.style.transition = 'top 0.5s ease';
  }
});

document.addEventListener("DOMContentLoaded", function() {
  var scrollToTopButton = document.getElementById('scroll-to-top-movil');
  var topSection = document.getElementById('bannerCarousel');
  
  scrollToTopButton.addEventListener('click', function() {
      topSection.scrollIntoView({ behavior: 'smooth' });
  });
  });

  document.addEventListener("DOMContentLoaded", function() {
    var scrollToTopButton = document.getElementById('scroll-to-top');
    var topSection = document.getElementById('bannerCarousel');
    
    scrollToTopButton.addEventListener('click', function() {
        topSection.scrollIntoView({ behavior: 'smooth' });
    });
    });
    
    document.addEventListener("DOMContentLoaded", function() {
      let slides = document.querySelectorAll("#mobile-slider .slide");
      let currentSlide = 0;
  
      slides[currentSlide].classList.add("active");
  
      setInterval(() => {
          slides[currentSlide].classList.remove("active");
          currentSlide = (currentSlide + 1) % slides.length;
          slides[currentSlide].classList.add("active");
      }, 3000); // Change every 3 seconds
  });
  
  
  document.addEventListener('DOMContentLoaded', (event) => {
    const slides = document.querySelectorAll('.item-slide');
    let currentIndex = 0;
  
    function showSlide(index) {
        slides.forEach((slide, i) => {
            slide.classList.remove('active');
            if (i === index) {
                slide.classList.add('active');
            }
        });
    }
  
    function nextSlide() {
        currentIndex = (currentIndex + 1) % slides.length;
        showSlide(currentIndex);
    }
  
    // Mostrar el primer slide inicialmente
    showSlide(currentIndex);
  
    // Cambiar de slide cada 3 segundos
    setInterval(nextSlide, 3000);
  });

  document.addEventListener("DOMContentLoaded", function() {
    var scrollToTopButton = document.getElementById('scroll-to-top-productos');
    var topSection = document.getElementById('bannerCarousel-productos');
    
    scrollToTopButton.addEventListener('click', function() {
      topSection.scrollIntoView({ behavior: 'smooth' });
    });
    });

    document.addEventListener('DOMContentLoaded', (event) => {
      const toastElList = [].slice.call(document.querySelectorAll('.toast'));
      const toastList = toastElList.map(function (toastEl) {
          const toast = new bootstrap.Toast(toastEl);
          toast.show();
          setTimeout(() => {
              toast.hide();
          }, 9000); // Tiempo en milisegundos antes de que la alerta comience a desvanecerse
          return toast;
      });
  });