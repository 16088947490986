import { createApp } from 'vue';
import "/assets/styles/app.css";
import ExampleSYMSA from './components/ExampleComponent.vue';
import "/assets/js/app.js";
import "/assets/js/blogdesk.js";
import "/assets/js/blogmobile.js";


const app = createApp({
});


app.component('symsaprueba', ExampleSYMSA);
app.mount('#app');
