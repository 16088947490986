<template>
    <div>
        <p>This is an example of a new components in VueJs</p>
    </div>
 </template>
 <script>
    export default {
        name: "symsaprueba"
    }
 </script>
 <style scoped>
 </style>