document.addEventListener('DOMContentLoaded', function () {
  const blogContainer = document.getElementById('blog-container');

  fetch('https://www.smtornillosytuercas.com/blog/wp-json/wp/v2/posts?_embed&per_page=3')
    .then(response => response.json())
    .then(posts => {
      posts.forEach(post => {
        const postCol = document.createElement('div');
        postCol.classList.add('col', 'card-text-blog');

        const link = document.createElement('a');
        link.href = post.link;
        link.target = '_blank'; 

        const img = document.createElement('img');
        img.classList.add('img-fluid');
        img.alt = 'Blog post image';
        img.src = getHighResolutionImage(post);

        link.appendChild(img);

        const dateP = document.createElement('p');
        dateP.classList.add('text-date');
        dateP.textContent = formatDate(post.date_gmt);
        dateP.style.marginTop = '1vw'; 


        const titleP = document.createElement('p');
        titleP.classList.add('text-blog');
        titleP.innerHTML = post.title.rendered;

        const linkDiv = document.createElement('div');
        linkDiv.classList.add('btn-group-blog');
        linkDiv.style.marginTop = '2vw'; 

        const linkIcon = document.createElement('a');
        linkIcon.href = post.link;
        linkIcon.target = '_blank';
        linkIcon.classList.add('button-link-blog');
        linkIcon.innerHTML = '<i class="fas fa-arrow-right"></i>';

        linkDiv.appendChild(linkIcon);
        postCol.appendChild(link);
        postCol.appendChild(dateP);
        postCol.appendChild(titleP);
        postCol.appendChild(linkDiv);
        blogContainer.appendChild(postCol);
      });
    })
    .catch(error => console.error('Error fetching posts:', error));

  function formatDate(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  function getHighResolutionImage(post) {
    if (post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0]) {
      const media = post._embedded['wp:featuredmedia'][0];
      return media.media_details.sizes.full ? media.media_details.sizes.full.source_url :
             media.media_details.sizes.large ? media.media_details.sizes.large.source_url :
             media.media_details.sizes.medium_large ? media.media_details.sizes.medium_large.source_url :
             media.media_details.sizes.medium ? media.media_details.sizes.medium.source_url :
             media.source_url;
    }
    return 'default-image.jpg'; // Ruta a una imagen por defecto
  }
});
